import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';

const Section = styled.section`
  margin-bottom: ${$.layout().margin5}px;
`;

const Container = styled.div`
  iframe {
    width: 100%;
    height: 400px;
  }
`;

const Map = () => (
  <Section>
    <Container>
      <Fade bottom distance="100px">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJy5dcgkfpyjERiFwqNM253w4&key=AIzaSyB7oILpZvG6iggp-rz3w89938MxhAVrntw"
          frameBorder="0"
          title="zennhrb_map"
        />
      </Fade>
    </Container>
  </Section>
);

export default Map;
