import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import FacebookIcon from '../../assets/icons/facebook.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import FaxIcon from '../../assets/icons/fax.svg';
import EmailIcon from '../../assets/icons/email.svg';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div``;

const Title = styled.h2`
  font-size: 30px;
  font-family: Lato Regular;
  color: ${$.color.base};
  text-align: center;
  margin-bottom: ${$.layout().margin2}px;
  line-height: 1.2em;
`;

const ChannelTitle = styled(Title)`
  margin-bottom: ${$.layout().margin4}px;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 30px;
  grid-column-gap: 40px;
  padding: 0 ${$.layout().padding2}px;

  ${$.device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${$.device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: ${$.dropShadow.normal};
  border-radius: 15px;
  padding: ${$.layout().padding2}px;
  background-color: ${$.color.white};
  height: 160px;

  & > svg {
    width: 40px;
    height: 40px;
    margin-bottom: ${$.layout().margin2}px;
    fill: ${$.color.black};
  }
`;

const OperatingHours = styled.div`
  font-size: 18px;
  line-height: 1.5em;
  font-family: Lato Regular;
  margin-bottom: ${$.layout().margin5}px;
  text-align: center;
`;

const CardSubtitle = styled.div`
  font-size: 15px;
  font-family: Lato Regular;
`;

const CardLink = styled.a`
  color: ${$.color.gray1};
  font-size: 17px;
  font-family: Lato Bold;
`;

const Channels = () => (
  <Section>
    <Container>
      <Fade bottom distance="100px">
        <Title>Operating Hours</Title>
        <OperatingHours>
          <div>Mon - Fri: 8:00AM to 5:00PM</div>
          <div>Sat: 8:00AM to 1:00PM</div>
          <div>Sun: CLOSED</div>
        </OperatingHours>
      </Fade>
      <Fade bottom distance="100px">
        <ChannelTitle>You can reach us through these channels</ChannelTitle>
      </Fade>

      <Cards>
        <Fade bottom distance="100px">
          <Card>
            <FacebookIcon />
            <CardSubtitle>Our Facebook:</CardSubtitle>
            <CardLink
              href="https://www.facebook.com/ZENNHRBSB/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @ZENNHRBSB
            </CardLink>
          </Card>
          <Card>
            <EmailIcon />
            <CardSubtitle>Write to us:</CardSubtitle>
            <CardLink
              href="mailto:zennhrbsb@yahoo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              zennhrbsb@yahoo.com
            </CardLink>
          </Card>
          <Card>
            <PhoneIcon />
            <CardSubtitle>Call us:</CardSubtitle>
            <CardLink
              href="tel:6053229022"
              target="_blank"
              rel="noopener noreferrer"
            >
              +605 - 322 9022
            </CardLink>
            <CardLink
              href="tel:6053223022"
              target="_blank"
              rel="noopener noreferrer"
            >
              +605 - 322 3022
            </CardLink>
          </Card>
          <Card>
            <FaxIcon />
            <CardSubtitle>Fax us:</CardSubtitle>
            <CardLink
              href="tel:6053225022"
              target="_blank"
              rel="noopener noreferrer"
            >
              +605 - 322 5022
            </CardLink>
          </Card>
        </Fade>
      </Cards>
    </Container>
  </Section>
);

export default Channels;
