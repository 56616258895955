import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import axios from 'axios';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import Loader from '../../assets/icons/loader.svg';

const Section = styled.section`
  color: ${$.color.base};
  margin-bottom: ${$.layout().margin5}px;
`;

const Container = styled.div`
  position: relative;
  color: ${$.color.base};
`;

const FormContainer = styled.form`
  padding: 0 ${$.layout().padding1}px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'companyName email'
    'contactNumber contactPerson'
    'projectTitle projectTitle'
    'products products'
    'quantity quantity'
    'specialRequest specialRequest'
    'submitButton submitButton';
  grid-gap: ${$.layout().margin2}px;
  margin-bottom: ${$.layout().margin3}px;

  ${$.device.mobile} {
    grid-template-areas:
      'companyName companyName'
      'email email'
      'contactNumber contactNumber'
      'contactPerson contactPerson'
      'projectTitle projectTitle'
      'products products'
      'quantity quantity'
      'specialRequest specialRequest'
      'submitButton submitButton';
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Label = styled.label`
  font-family: 'Lato Bold';
  font-size: 20px;
  margin-bottom: ${$.layout().margin2}px;
`;

const inputStyles = css`
  background-color: #f5f5f5;
  font-family: 'Lato Regular';
  font-size: 18px;
  padding: ${$.layout().padding2}px ${$.layout().padding2}px;
  transition: box-shadow 0.3s ${$.easingFn.standard},
    background-color 0.3s ${$.easingFn.standard};
  border-radius: ${$.border().radius3}px;
  box-sizing: border-box;
  border: 2px solid #f5f5f5;
  box-shadow: none !important;
  outline: none;

  &:focus {
    background-color: ${$.color.white};
  }

  &:hover {
    box-shadow: ${$.dropShadow.normal} !important;
  }

  &::placeholder {
    opacity: 0.4;
  }
`;

const Input = styled.input`
  ${inputStyles}
`;

const TextArea = styled.textarea`
  resize: vertical;
  min-height: 200px;
  ${inputStyles}
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  grid-area: submitButton;
  color: ${$.color.white};
`;

const SubmitButton = styled.div`
  font-family: Lato Bold;
  background-color: ${$.color.red};
  padding: 8px 16px;
  border: 2px solid transparent;
  border-radius: 999px;
  min-width: 150px;
  transition: all 0.3s ease;
  margin-top: ${$.layout().margin1}px;
  text-align: center;

  &:hover {
    cursor: pointer;
    border: 2px solid ${$.color.red};
    color: ${$.color.red};
    background-color: ${$.color.white};
    & > svg {
      fill: ${$.color.red};
    }
  }

  ${$.device.mobile} {
    margin-top: ${$.layout().margin1}px;
    padding: 12px;
    flex: 1;
  }
`;

const ThanksContainer = styled.div`
  font-family: Lato Bold;
  font-size: 34px;
  text-align: center;

  ${$.device.mobile} {
    font-size: 30px;
  }
`;

const ThanksSubtitle = styled.div`
  margin-top: ${$.layout().margin2}px;
  font-family: Lato Regular;
  font-size: 16px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: ${({ isLoading }) => (isLoading ? 'auto' : 'none')};
  opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;

  & > svg {
    height: 50px;
    width: 50px;
    animation: ${rotate} 2s linear infinite;
  }
`;

const Form = () => {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [projectTitle, setProjectTitle] = useState('');
  const [products, setProducts] = useState('');
  const [quantity, setQuantity] = useState('');
  const [specialRequest, setSpecialRequest] = useState('None');
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);

  const handleFormSubmit = () => {
    if (
      !(
        companyName &&
        email &&
        contactNumber &&
        contactPerson &&
        projectTitle &&
        products &&
        quantity
      )
    ) {
      // eslint-disable-next-line
      alert('Incomplete form, please check the missing fields.');
      return;
    }

    setLoading(true);

    axios({
      method: 'POST',
      url: 'https://2z5ksqo7n6.execute-api.ap-southeast-1.amazonaws.com/prod/enquiry',
      data: JSON.stringify({
        companyName,
        email,
        contactNumber,
        contactPerson,
        projectTitle,
        products,
        quantity,
        specialRequest,
      }),
    })
      .then(() => {
        setLoading(false);
        setFormSent(true);
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.log(e);
      });
  };

  return (
    <Section>
      <Fade bottom distance="100px">
        <Container>
          {loading && (
            <LoaderContainer isLoading={loading}>
              <Loader />
            </LoaderContainer>
          )}
          {!formSent ? (
            <FormContainer>
              <InputContainer style={{ gridArea: 'companyName' }}>
                <Label htmlFor="companyName">Company Name</Label>
                <Input
                  id="companyName"
                  onChange={({ target: { value } }) => setCompanyName(value)}
                  placeholder="Zenn HRB Sdn Bhd"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'email' }}>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  onChange={({ target: { value } }) => setEmail(value)}
                  placeholder="john.smith@example.com"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'contactNumber' }}>
                <Label htmlFor="contactNumber">Contact Number</Label>
                <Input
                  id="contactNumber"
                  onChange={({ target: { value } }) => setContactNumber(value)}
                  placeholder="012-12345678"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'contactPerson' }}>
                <Label htmlFor="contactPerson">Contact Person</Label>
                <Input
                  id="contactPerson"
                  onChange={({ target: { value } }) => setContactPerson(value)}
                  placeholder="John Smith"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'projectTitle' }}>
                <Label htmlFor="projectTitle">Project Title</Label>
                <Input
                  id="projectTitle"
                  onChange={({ target: { value } }) => setProjectTitle(value)}
                  placeholder="Project XYZ"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'products' }}>
                <Label htmlFor="products">Products</Label>
                <Input
                  id="products"
                  onChange={({ target: { value } }) => setProducts(value)}
                  placeholder="Tell us more about your products"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'quantity' }}>
                <Label htmlFor="quantity">Quantity</Label>
                <Input
                  id="quantity"
                  onChange={({ target: { value } }) => setQuantity(value)}
                  placeholder="100"
                />
              </InputContainer>
              <InputContainer style={{ gridArea: 'specialRequest' }}>
                <Label htmlFor="specialRequest">Special Request</Label>
                <TextArea
                  id="specialRequest"
                  rows="15"
                  onChange={({ target: { value } }) => setSpecialRequest(value)}
                  placeholder="Tell us more about any special requests"
                />
              </InputContainer>
              <SubmitButtonContainer>
                <SubmitButton onClick={handleFormSubmit}>
                  Submit enquiry form
                </SubmitButton>
              </SubmitButtonContainer>
            </FormContainer>
          ) : (
            <ThanksContainer>
              Thanks for contacting us!
              <ThanksSubtitle>
                We will try to get back to you in 3 working days.
              </ThanksSubtitle>
            </ThanksContainer>
          )}
        </Container>
      </Fade>
    </Section>
  );
};

export default Form;
