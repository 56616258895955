import React from 'react';

import Seo from '../components/seo';
import Banner from '../components/ContactUs/Banner';
import Form from '../components/ContactUs/Form';
import Map from '../components/ContactUs/Map';
import Channels from '../components/ContactUs/Channels';

const ContactUs = () => (
  <>
    <Seo title="Contact Us | Zenn HRB" />
    <Banner />
    <Channels />
    <Map />
    <Form />
  </>
);

export default ContactUs;
